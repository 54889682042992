import { Link } from "react-router-dom";
import Footer from "../layout/footer";
import Header from "../layout/header";

export default function Author() {

    return (
        <>
            <Header />
            <section className="mt-90 mb-50 pt-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 m-auto">
                            <div className="author">
                                <div className="image">
                                    <Link to="/" className="image">
                                        <img src="assets/img/author/1.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <h5>Hi, I'm Emma Dalia</h5>
                                    <div className="link">8 Articles</div>
                                    <p>
                                        Etiam vitae dapibus rhoncus. Eget etiam aenean nisi montes felis
                                        pretium donec veni. Pede vidi condimentum et aenean hendrerit.
                                        Quis sem justo nisi varius.
                                    </p>
                                    <div className="social-icones">
                                        <ul className="list-inline">
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-facebook" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-instagram" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-twitter" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-youtube" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-behance" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fab fa-dribbble" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Posts*/}
            <section className="mb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            {/*Post-1*/}
                            <div className="post-round">
                                <div className="image">
                                    <Link to="post-default.html">
                                        <img src="assets/img/blog/5.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <Link to="blog-grid.html" className="categorie">
                                        <i className="icon_circle-slelected" />
                                        Art &amp; Design
                                    </Link>
                                    <h5>
                                        <Link to="post-default.html">
                                            How to Choose Outfits for Work for woman and men{" "}
                                        </Link>
                                    </h5>
                                    <div className="meta">
                                        <ul className="list-inline">
                                            <li>
                                                <Link to="author.html">Emma Dalia</Link>
                                            </li>
                                            <li className="dot" />
                                            <li>2 Months Ago</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            {/*Post-2*/}
                            <div className="post-round">
                                <div className="image">
                                    <Link to="post-default.html">
                                        <img src="assets/img/blog/3.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <Link to="blog-grid.html" className="categorie">
                                        <i className="icon_circle-slelected" />
                                        Interior
                                    </Link>
                                    <h5>
                                        <Link to="post-default.html">
                                            Prepare easy brekfast for a weekend at home
                                        </Link>
                                    </h5>
                                    <div className="meta">
                                        <ul className="list-inline">
                                            <li>
                                                <Link to="author.html">Emma Dalia</Link>
                                            </li>
                                            <li className="dot" />
                                            <li>2 Months Ago</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            {/*Post-3*/}
                            <div className="post-round">
                                <div className="image">
                                    <Link to="post-default.html">
                                        <img src="assets/img/blog/30.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <Link to="blog-grid.html" className="categorie">
                                        <i className="icon_circle-slelected" />
                                        Pants
                                    </Link>
                                    <h5>
                                        <Link to="post-default.html">
                                            How to make cokies with Chocolate for your kids?
                                        </Link>
                                    </h5>
                                    <div className="meta">
                                        <ul className="list-inline">
                                            <li>
                                                <Link to="author.html">Emma Dalia</Link>
                                            </li>
                                            <li className="dot" />
                                            <li>2 Months Ago</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            {/*Post-4*/}
                            <div className="post-round">
                                <div className="image">
                                    <Link to="post-default.html">
                                        <img src="assets/img/blog/25.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <Link to="blog-grid.html" className="categorie">
                                        <i className="icon_circle-slelected" />
                                        Interior
                                    </Link>
                                    <h5>
                                        <Link to="post-default.html">
                                            Discovering the Natural history of trinidad
                                        </Link>
                                    </h5>
                                    <div className="meta">
                                        <ul className="list-inline">
                                            <li>
                                                <Link to="author.html">Emma Dalia</Link>
                                            </li>
                                            <li className="dot" />
                                            <li>2 Months Ago</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            {/*Post-5*/}
                            <div className="post-round">
                                <div className="image">
                                    <Link to="post-default.html">
                                        <img src="assets/img/blog/20.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <Link to="blog-grid.html" className="categorie">
                                        <i className="icon_circle-slelected" />
                                        Art &amp; Design
                                    </Link>
                                    <h5>
                                        <Link to="post-default.html">
                                            the best dressed men in the world
                                        </Link>
                                    </h5>
                                    <div className="meta">
                                        <ul className="list-inline">
                                            <li>
                                                <Link to="author.html">Emma Dalia</Link>
                                            </li>
                                            <li className="dot" />
                                            <li>2 Months Ago</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            {/*Post-6*/}
                            <div className="post-round">
                                <div className="image">
                                    <Link to="post-default.html">
                                        <img src="assets/img/blog/29.jpg" alt="" />
                                    </Link>
                                </div>
                                <div className="content">
                                    <Link to="blog-grid.html" className="categorie">
                                        <i className="icon_circle-slelected" />
                                        Pants
                                    </Link>
                                    <h5>
                                        <Link to="post-default.html">
                                            Enjoy my favourite molten chocolate cake
                                        </Link>
                                    </h5>
                                    <div className="meta">
                                        <ul className="list-inline">
                                            <li>
                                                <Link to="author.html">Emma Dalia</Link>
                                            </li>
                                            <li className="dot" />
                                            <li>2 Months Ago</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}