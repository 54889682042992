import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';


const HeroCarousel = () => {
  return (
    <section className="hero hero-carousel d-flex align-items-center">
      <div className="container">
        <OwlCarousel
          className="owl-theme"
          loop
          margin={5}
          items={1} // Show one item per slide
          autoplay
          autoplayTimeout={3000} // 3 seconds per slide
        >
          {/* Slide 1 */}
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="post">
                <a href="blog-grid.html" className="categorie">
                  <i className="icon_circle-slelected" /> Fashion
                </a>
                <h2>
                  <a href="post-default.html">
                    The biggest Spring/Summer 2021 trends for men
                  </a>
                </h2>
                <div className="info">
                  <ul className="list-inline">
                    <li>
                      <a href="author.html">Emma Dalia</a>
                    </li>
                    <li className="dot">•</li>
                    <li>2 Months Ago</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="image">
                <a href="post-default.html">
                  <img
                    src="assets/img/blog/27.jpg"
                    alt="Spring/Summer 2021 Trends"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>

          {/* Slide 2 */}
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="post">
                <a href="blog-grid.html" className="categorie">
                  <i className="icon_circle-slelected" /> Food
                </a>
                <h2>
                  <a href="post-default.html">
                    The Easiest loaf of Bread you'll ever bake
                  </a>
                </h2>
                <div className="info">
                  <ul className="list-inline">
                    <li>
                      <a href="author.html">Emma Dalia</a>
                    </li>
                    <li className="dot">•</li>
                    <li>2 Months Ago</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="image">
                <a href="post-default.html">
                  <img
                    src="assets/img/blog/4.jpg"
                    alt="Easiest Bread"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>

          {/* Slide 3 */}
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="post">
                <a href="blog-grid.html" className="categorie">
                  <i className="icon_circle-slelected" /> Plants
                </a>
                <h2>
                  <a href="post-default.html">
                    How to Bring your Plants Indoors for the fall and winter
                  </a>
                </h2>
                <div className="info">
                  <ul className="list-inline">
                    <li>
                      <a href="author.html">Emma Dalia</a>
                    </li>
                    <li className="dot">•</li>
                    <li>2 Months Ago</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="image">
                <a href="post-default.html">
                  <img
                    src="assets/img/blog/32.jpg"
                    alt="Indoor Plants"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </section>
  );
};

export default HeroCarousel;
