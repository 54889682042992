import './App.css';
import React, { useState } from 'react'
import {BrowserRouter as Router,Routes,Route,} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import Home from './pages/home';
import About from './pages/about';
import Blog from './pages/blog';
import Author from './pages/author';
import Contact from './pages/contact';


function App() {

  const [progress , setProgress] = useState(0)

  const handleProgress = () => {
    // setProgress(100);
    console.log('asdas');
  }
  return (
    <div className="App">
       <Router>
       <LoadingBar color="#FF0000" progress={progress} onLoaderFinished={() => setProgress(0)}/>
        <Routes>
          <Route path="/" element={<Home  onRouteChange = {handleProgress} />} />
          <Route path="about-us" element={<About  onRouteChange = {handleProgress} />} />
          <Route path="blog" element={<Blog  onRouteChange = {handleProgress} />} />
          <Route path="guest-author" element={<Author  onRouteChange = {handleProgress} />} />
          <Route path="contact" element={<Contact  onRouteChange = {handleProgress} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
