import React from "react";
import { Link } from "react-router-dom";

export default function Header(){


    return(
        <>
        
            {/* Navigation*/}
            <nav className="navbar navbar-expand-lg fixed-top">
          <div className="container">
            {/*logo*/}
            <div className="logo">
                <Link to="/">
                    <img src="assets/img/logo-dark.png" alt="" className="logo-dark" />
                    <img src="assets/img/logo-white.png" alt="" className="logo-white" />
                </Link>
            </div>
            {/*navbar-collapse*/}
            <div className="collapse navbar-collapse" id="main_nav">
              <ul className="navbar-nav ml-auto mr-auto">
                <li className="nav-item">
                  <Link
                    className="nav-link dropdown-toggle active"
                    to="/"
                  
                  >
                    {" "}
                    Home{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/about-us"
                  
                  >
                    {" "}
                    About{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/blog"
                  
                  >
                    {" "}
                    Blog{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/guest-author"
                  
                  >
                    {" "}
                    Guest Author{" "}
                  </Link>
                </li>
              
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    {" "}
                    Contact{" "}
                  </Link>
                </li>
              </ul>
            </div>
            {/*/*/}
            {/*navbar-right*/}
            <div className="navbar-right ml-auto">
              <div className="social-icones">
                <ul className="list-inline">
                  <li>
                    <Link to="#">
                      <i className="fab fa-facebook-f" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fab fa-twitter" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="theme-switch-wrapper">
                <label className="theme-switch" htmlFor="checkbox">
                  <input type="checkbox" id="checkbox" />
                  <span className="slider">
                    <i className="far  fa-sun icon-light" />
                    <i className="far fa-moon icon-dark" />
                  </span>
                </label>
              </div>
              <div className="search-icon">
                <i className="far fa-search" />
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#main_nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
            </div>
          </div>
        </nav>
        {/*Hero*/}
        </>
    )
}