import React from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import HeroCarousel from "../components/herobanner";
import Category from "../components/categories";
import Post from "../components/post";


export default function Home(){


    return(

        <>
        <Header/>
        <HeroCarousel/>
        <Category/>
        <Post/>
        {/*post-full*/}
        <section className="post-full bg-light">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-7">
                <div className="content">
                  <a href="blog-grid.html" className="categorie">
                    <i className="icon_circle-slelected" />
                    Art &amp; Design
                  </a>
                  <h3>
                    <a href="post-default.html">
                      Great design is a multi-layered relationship between human life
                      and its environment
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Doloribus unde officia dolor, sapiente ipsa molestiae voluptatem
                    deserunt nisi. Facilis consequuntur suscipit maiores magni
                    repudiandae .
                  </p>
                  <div className="info">
                    <ul className="list-inline">
                      <li>
                        <a href="author.html">
                          <img src="assets/img/author/1.jpg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a href="author.html">Emma Dalia</a>
                      </li>
                      <li className="dot" />
                      <li>2 Months Ago</li>
                      <li className="dot" />
                      <li>5 min Read</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="image">
                  <img src="assets/img/blog/26.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Posts*/}
        <section className="mb-70 mt-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="post-list">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/9.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Interior
                    </a>
                    <h5>
                      <a href="post-default.html">
                        12 rules an interior decorator never forgets
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>5 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="post-list">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/10.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Art &amp; design
                    </a>
                    <h5>
                      <a href="post-default.html">
                        You don’t have to be ‘a creative’ to be creative.
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>5 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="post-list">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/31.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Interior
                    </a>
                    <h5>
                      <a href="post-default.html">
                        How to Design Modern Spaces That Don't Feel Cold
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>5 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="post-list">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/19.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Pants
                    </a>
                    <h5>
                      <a href="post-default.html">
                        How to Reuse Coffee Grounds for Your Plants
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>5 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="post-list">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/17.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Fashion
                    </a>
                    <h5>
                      <a href="post-default.html">
                        Outfit ideas: what to wear on Christmas Day
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>5 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="post-list">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/16.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Livestyle
                    </a>
                    <h5>
                      <a href="post-default.html">
                        How to Choose Outfits for Work for woman and men
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>5 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="post-list">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/12.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Plants
                    </a>
                    <h5>
                      <a href="post-default.html">
                        How to Grow and Care for Aloe Vera Plants
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>5 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="post-list">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/2.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Food
                    </a>
                    <h5>
                      <a href="post-default.html">
                        How to Cook Chicken Perfectly Every Time
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>5 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*bottom*/}
        <Footer/>
      </>
      
    )
}