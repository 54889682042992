import React from "react";

const Post = () =>{

    return(
        <>
        
          {/*Posts*/}
          <section className="mt-100 mb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                {/*Post-1*/}
                <div className="post-round">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/5.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Art &amp; Design
                    </a>
                    <h5>
                      <a href="post-default.html">
                        It’s easier to ask forgiveness than it is to get permission.{" "}
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>2 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {/*Post-2*/}
                <div className="post-round">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/3.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Interior
                    </a>
                    <h5>
                      <a href="post-default.html">
                        {" "}
                        Lighting tips for every room in the house
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>2 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {/*Post-3*/}
                <div className="post-round">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/30.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Plants
                    </a>
                    <h5>
                      <a href="post-default.html">
                        45 Summer Flowers for a Stunning Home Garden
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>2 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {/*Post-4*/}
                <div className="post-round">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/25.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Interior
                    </a>
                    <h5>
                      <a href="post-default.html">
                        12 rules an interior decorator never forgets
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>2 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {/*Post-5*/}
                <div className="post-round">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/20.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Art &amp; Design
                    </a>
                    <h5>
                      <a href="post-default.html">
                        The best way to predict the future is to create it
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>2 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                {/*Post-6*/}
                <div className="post-round">
                  <div className="image">
                    <a href="post-default.html">
                      <img src="assets/img/blog/29.jpg" alt="" />
                    </a>
                  </div>
                  <div className="content">
                    <a href="blog-grid.html" className="categorie">
                      <i className="icon_circle-slelected" />
                      Plants
                    </a>
                    <h5>
                      <a href="post-default.html">
                        30 Perfect Flowers for a Fall Garden
                      </a>
                    </h5>
                    <div className="meta">
                      <ul className="list-inline">
                        <li>
                          <a href="author.html">Emma Dalia</a>
                        </li>
                        <li className="dot" />
                        <li>2 Months Ago</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}
export default Post;